<template>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="10" class="pt-4">
      <div class="flex justify-center">
        <img src="@/assets/images/logo/rellopay-logo.svg" alt="Rello" v-if="appName ==='RelloPay'" class="h-12 w-auto"/>
        <img src="@/assets/images/logo/flippay-logo.svg" alt="FlipPay" v-else-if="appName ==='FlipPay'" class="h-12 w-auto"/>
      </div>
      <vs-card class="mt-3 md:mt-8 bg-transparent otp-block">
        <div slot="header" class="px-6 md:px-8 py-4 md:py-6 text-center">
          <feather-icon icon="UserIcon" svgClasses="stroke-current mid-blue w-8 h-8" class="mb-3" />
          <h3 class="text-xl font-normal leading-normal">Set up your account</h3>
        </div>
        <div class="px-6 lg:px-8 py-4 md:py-8">
          <p class="text-sm md:text-base font-light">To keep your account secure, we need to confirm it's you by sending a one-time passcode to your email.</p>
          <vs-button color="primary" v-round class="w-full mt-6 md:mt-8 large" @click="sendCodeToSetupMerchant">Send code</vs-button>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import { clearLocalStorage } from "../../../helper/localStorage.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: localStorage.getItem("email"),
      partnerId: this.$route.params.partnerId,
      hash: this.$route.query.hash,
      appName: process.env.VUE_APP_NAME
    };
  },

  methods: {
    ...mapActions("partner", ["sendOtpCodeToMerchant"]),

    sendCodeToSetupMerchant() {
      const data = { partnerId: this.partnerId, hash: this.hash };

      this.sendOtpCodeToMerchant(data).then((res) => {
        this.$vs.notify({
          title: "OTP",
          text: "Verification code has been sent",
          color: "success",
          iconPack: "feather",
          position: "top-right",
        });

        this.$router.push({
          name: "verifyOtp",
          params: { partnerId: this.partnerId },
          query: { hash:this.hash,email:res.data.data }
        });
      });
    },
  },

  created() {
    clearLocalStorage();
  }
};
</script>
